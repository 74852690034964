.page-container {
  display: flex;
  margin-left: 5%;
  margin-right: 5%;
}
.footer-container {
  padding-top: 24px;
  padding-bottom: 24px;
}
.appbar-style {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.05);
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  /* 10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  } */
  20% {
    transform: translate(-3px, 0px) rotate(0.5deg);
  }
  /* 30% {
    transform: translate(3px, 2px) rotate(0deg);
  } */
  40% {
    transform: translate(1px, -1px) rotate(0.5deg);
  }
  /* 50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  } */
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  /* 70% {
    transform: translate(3px, 1px) rotate(-1deg);
  } */
  80% {
    transform: translate(-1px, -1px) rotate(0.5deg);
  }
  /* 90% {
    transform: translate(1px, 2px) rotate(0deg);
  } */
  /* 100% {
    transform: translate(1px, -2px) rotate(-0.5deg);
  } */
}

.in-button {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

.footer-style {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.05);
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: space-around;
  justify-content: space-evenly;
}
.card-style {
  display: flex;
  box-shadow: 0 1.5rem 1.5rem 0 rgba(0, 0, 0, 0.05);
}
.button-style {
  font-family: 'Cera Basic Regular';
  font-size: 1rem;
  font-weight: 400;
}
.card-home {
  display: flex;
  width: 1;
  height: 1;
  margin: 2rem;
}
.logo-text {
  font-family: 'Cera Basic Regular';
  font-weight: 100;
  font-size: small;
  align-self: center;
}
.text-fonts {
  font-family: 'Cera Basic Regular';
}
.text-fonts-small {
  font-family: 'Cera Basic Regular';
  font-weight: 100;
  font-size: small;
}
.vertical-flow {
  flex-direction: column;
}
.horizontal-flow {
  flex-direction: row;
}
