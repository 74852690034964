.controls{
  padding: 1rem;
}
.grid-container {
  display: block;
  width: fit-content;
  height: fit-content;
  margin:1rem;
  padding: 0.5rem;
  background-color: beige;
}
.grid-holder {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(9, 2rem);
  grid-template-rows: repeat(9, 2rem);
  background-color: beige;
  width: fit-content;
}
div:focus {
  background-color: #0000ff;
}
.level-style{
  color: #0000ff;
  font-size: 1rem;
  width: 8rem;
  margin:0.5rem;
}
